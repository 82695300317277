import styled from 'styled-components'

const BaseTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  & > tbody > tr > th {
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    color: #adadad;
    text-align: left;

    &:first-child {
      padding-left: 40px;
    }

    &.image,
    &.checkbox {
      width: 120px;
      padding-left: 10px;
      text-align: center;
    }

    &.action {
      text-align: center;
    }

    &.dashboard-data {
      text-align: center;
      white-space: pre-wrap;
    }
  }

  & > tbody > tr > td {
    height: 50px;
    padding: 10px;
    color: #323232;
    text-align: left;

    &:first-child {
      padding-left: 40px;
    }

    &.image,
    &.checkbox {
      width: 120px;
      padding-left: 10px;
      text-align: center;
    }

    &.action {
      width: 150px;
      padding: 10px 0;
      text-align: center;
    }

    &.dashboard-data {
      width: 120px;
      line-height: 1.4rem;
      text-align: center;
      white-space: pre-wrap;
    }
  }
`

export default BaseTable
