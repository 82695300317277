import styled, { css } from 'styled-components'

type Props = {
  isHeader?: boolean
  isOnClick?: boolean
  isTemp?: string
  backgroundColor?: string
}

const BaseTableRow = styled.tr<Props>`
  white-space: pre-wrap;
  pointer-events: ${(props) => props.isTemp && 'none'};
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.isHeader && '#fafafa'};
  opacity: ${(props) => props.isTemp && '0.5'};
  transition: all 100ms ease-in;

  ${(props) =>
    !props.isHeader &&
    props.isOnClick &&
    css`
      :hover {
        cursor: pointer;
        background-color: #fafafa;
      }
    `}
`

export default BaseTableRow
