import { isEmpty } from 'lodash'
import styled from 'styled-components'
import BaseTableRow from 'components/BaseTableRow'
import BaseTable from 'components/common/BaseTable'
import { NextImageWithFallback } from 'components/common/NextImageWithFallback'
import userIcon from 'images/legacy/user-icon.png'

const ProfileContainer = styled.div`
  display: flex;
`

const NameLabel = styled.span`
  margin: auto auto auto 1rem;
  line-height: 1.4rem;
`

const DashboardActivityListTable = ({
  company,
  aces = [],
  companyDashboardData,
  dashboardDataPerAce,
}) => (
  <BaseTable>
    <tbody>
      <BaseTableRow isHeader={true}>
        <th />
        <th className="dashboard-data">{`オファー数`}</th>
        <th className="dashboard-data">{`マッチング数\n(マッチング率)`}</th>
        <th className="dashboard-data">{`気になる数`}</th>
      </BaseTableRow>
      <BaseTableRow backgroundColor="white">
        <td style={{ paddingLeft: '20px', maxWidth: '150px' }}>
          <ProfileContainer>
            <NextImageWithFallback
              src={company.logoURL || userIcon.src}
              width={50}
              height={50}
              alt={company.name}
              style={{
                borderRadius: '50%',
                border: '1px solid #d8d8d8',
                objectFit: 'cover',
              }}
              fallbackSrc={userIcon.src}
            />
            <NameLabel>{company.name}</NameLabel>
          </ProfileContainer>
        </td>
        <td className="dashboard-data">
          {`${companyDashboardData.offeredCount}通`}
        </td>
        <td className="dashboard-data">
          {`${companyDashboardData.matchingCount}人\n(${(
            (companyDashboardData.matchingRate || 0) * 100
          ).toFixed(1)}%)`}
        </td>
        <td className="dashboard-data">
          {`${companyDashboardData && companyDashboardData.likeCount}件`}
        </td>
      </BaseTableRow>
      {aces.map((ace) => {
        const dashboardData = dashboardDataPerAce && dashboardDataPerAce[ace.id]
        if (ace.registrationStatus === 'temp' || isEmpty(dashboardData)) {
          return
        }

        return (
          <BaseTableRow
            key={ace.id}
            backgroundColor="white"
          >
            <td style={{ paddingLeft: '20px', maxWidth: '150px' }}>
              <ProfileContainer>
                <NextImageWithFallback
                  src={ace.profileImageURL || userIcon.src}
                  width={50}
                  height={50}
                  alt={ace.name}
                  style={{
                    borderRadius: '50%',
                    border: '1px solid #d8d8d8',
                    objectFit: 'cover',
                  }}
                  fallbackSrc={userIcon.src}
                />
                <NameLabel>{ace.name}</NameLabel>
              </ProfileContainer>
            </td>
            <td className="dashboard-data">
              {`${dashboardData.offeredCount}通`}
            </td>
            <td className="dashboard-data">
              {`${dashboardData.matchingCount}人\n(${(
                (dashboardData.matchingRate || 0) * 100
              ).toFixed(1)}%)`}
            </td>
            <td className="dashboard-data">{`${dashboardData.likeCount}件`}</td>
          </BaseTableRow>
        )
      })}
    </tbody>
  </BaseTable>
)

export default DashboardActivityListTable
